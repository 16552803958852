import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { userApi } from "./user";
import { candidateCreateOzgelecekSlice, companyCreateAdvertisementSlice } from "./slices";
import { dmsApi } from "./dms";
import { paymentApi } from "./payment";
import { notificationApi } from "./notification";


export const store = configureStore({
    devTools: true,
    reducer: {
        auth: authSlice,
        candidateCreateOzgelecek: candidateCreateOzgelecekSlice,
        companyCreateAdvertisement: companyCreateAdvertisementSlice,
        [userApi.reducerPath]: userApi.reducer,
        [dmsApi.reducerPath]: dmsApi.reducer,
        [paymentApi.reducerPath]: paymentApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(userApi.middleware)
            .concat(dmsApi.middleware)
            .concat(paymentApi.middleware)
            .concat(notificationApi.middleware)
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch