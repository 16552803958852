import {
  AdvertisementStatusEnums,
  ExtraProductEnums,
  HigherEducationDegreeEnum,
  LanguageEnums,
  MutationVariablesPaginationSortEnums,
  OzgelecekFieldEnum,
  PlaceOfWorkEnums,
  PositionLevelEnums,
  ProductTypeEnums,
  WorkTypeEnums
} from "types"

export interface ICandidateAdvertisementsItem {
  _id: string
  createdBy: {
    _id: {
      _id: string
    }
    companyInformation: {
      companyName: string
      profileImage: string
    }
  } | null
  title: string
  status: AdvertisementStatusEnums
  // productType: ProductTypeEnums
  // extraProduct: ExtraProductEnums
  startDate: string
  quota: number
  isApplied: boolean
  quotaVisibleToApplicant: boolean
  field: OzgelecekFieldEnum
  department: string
  language: LanguageEnums
  description: string
  place: PlaceOfWorkEnums
  workType: WorkTypeEnums
  cities: string[]
  districts: string[]
  positionLevel: PositionLevelEnums
  requiredAgeInterval: number
  requiredEducationLevel: HigherEducationDegreeEnum
  requiredSkills: Array<string>
  requiredLanguages: {
    name: string
    reading: LanguageEnums
    writing: LanguageEnums
    listening: LanguageEnums
  }
  militaryServiceRequired: boolean
  requiredDriverLicense: string
  companyInfoVisible: boolean
  images: Array<string>
  //replyNotification: string
  //customReplyNotification: {
  //  subject: string
  //  content: string
  //}
  createdAt: string
  updatedAt: string
}
export interface ICandidateAdvertisementsResponse {
  count: number,
  data: Array<ICandidateAdvertisementsItem>
}


export interface ICandidateAdvertisementsVariables {
  pagination: {
    page: number,
    pageSize: number,
    sort?: MutationVariablesPaginationSortEnums,
  },

  filterOption: {

    filter?: {
      companyId?: string | null,

      title?: string | null,
      city?: string | null,
      department?: string | null,
      description?: string | null,
      district?: string | null,
      field?: OzgelecekFieldEnum | string | null,
      language?: string | null,
      militaryServiceRequired?: boolean | null,
      place?: PlaceOfWorkEnums | null,
      positionLevel?: PositionLevelEnums | string | null,
      requiredAgeInterval?: number | null,
      requiredDriverLicense?: string | null,
      requiredEducationLevel?: HigherEducationDegreeEnum | null,
      requiredLanguages?: Array<string> | null,
      requiredSkills?: Array<string> | null,
      startDate?: string | null,
      workType?: WorkTypeEnums | string | null,
    },

    search?: {
      all: string | null,
      city?: string | null,
      department?: string | null,
      description?: string | null,
      district?: string | null,
      title?: string | null,
    }
  },





}


export const candidate_advertisements = (variables: ICandidateAdvertisementsVariables) => {

  const data = JSON.stringify({
    query: `
    query Candidate_advertisements($filterOption: FilterAdvertisementOptionForCandidate, $pagination: PaginationWithAdvertisementSort) {
      candidate_advertisements(filterOption: $filterOption, pagination: $pagination) {
        count
        data {
          _id
          
        createdBy {
          _id 
         companyInformation {
            companyName
            profileImage
          }
        }

          title
          status
          isApplied
          startDate
          quota
          field
          department
          language
          description
          place
          workType
          city
          district
          positionLevel
          requiredAgeInterval
          requiredEducationLevel
          requiredSkills
          requiredLanguages {
            name
            reading
            writing
            listening
          }
          militaryServiceRequired
          requiredDriverLicense
          images
          createdAt
          updatedAt
          companyInfoVisible
          quotaVisibleToApplicant
        }
      }
    }
    `,
    variables: {
      "pagination": {
        "page": variables.pagination?.page ?? null,
        "pageSize": variables.pagination?.pageSize ?? null,
        "sort": variables.pagination?.sort ?? null
      },

      "filterOption": {
        "filter": {
          "companyId": variables.filterOption.filter?.companyId ?? null,

          "title": variables.filterOption.filter?.title ?? null,
          "city": variables.filterOption.filter?.city ?? null,
          "department": variables.filterOption.filter?.department ?? null,
          "description": variables.filterOption.filter?.description ?? null,
          "district": variables.filterOption.filter?.district ?? null,
          "field": variables.filterOption.filter?.field ?? null,
          "language": variables.filterOption.filter?.language ?? null,
          "militaryServiceRequired": variables.filterOption.filter?.militaryServiceRequired ?? null,
          "place": variables.filterOption.filter?.place ?? null,
          "positionLevel": variables.filterOption.filter?.positionLevel ?? null,
          "requiredAgeInterval": variables.filterOption.filter?.requiredAgeInterval ?? null,
          "requiredDriverLicense": variables.filterOption.filter?.requiredDriverLicense ?? null,
          "requiredEducationLevel": variables.filterOption.filter?.requiredEducationLevel ?? null,
          // "requiredLanguages": [],
          "requiredSkills": variables.filterOption.filter?.requiredSkills ?? null,
          "startDate": variables.filterOption.filter?.startDate ?? null,
          "workType": variables.filterOption.filter?.workType ?? null
        },
        "search": {
          "city": variables?.filterOption?.search?.all ?? null,
          "department": variables?.filterOption?.search?.all ?? null,
          "description": variables?.filterOption?.search?.all ?? null,
          "district": variables?.filterOption?.search?.all ?? null,
          "title": variables?.filterOption?.search?.all ?? null,
        }
      },


    }

  })

  return data

}