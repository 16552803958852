import { BrowserRouter, Routes } from 'react-router-dom';
import { AnonymousRouter, referencerRouter } from 'routes';
import { conversionRouter } from './ConversionRouter';


export function AppRouter() {

    const userType = "anonymous"


    const renderUserTypeRouter = () => {

        switch (userType) {
            case "anonymous":
                return AnonymousRouter()
            default:
                return AnonymousRouter()
        }

    }


    return (
        <BrowserRouter>
            <Routes>
                {conversionRouter()}
                {/* {referencerRouter()} */}
                {/* {renderUserTypeRouter()} */}
            </Routes>
        </BrowserRouter>
    );
}
