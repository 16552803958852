import {
  AdvertisementStatusEnums,
  ExtraProductEnums,
  HigherEducationDegreeEnum,
  LanguageEnums,
  OzgelecekFieldEnum,
  PlaceOfWorkEnums,
  PositionLevelEnums,
  ProductTypeEnums,
  WorkTypeEnums
} from "types"

export interface IAnonymousRandomAdvertisementsItem {
  _id: string
  createdBy: {
    _id: {
      _id: string
    }
    companyInformation: {
      companyName: string
      profileImage: string
    }
  } | null
  title: string
  status: AdvertisementStatusEnums
  productType: ProductTypeEnums
  extraProduct: ExtraProductEnums
  startDate: string
  quota: number
  quotaVisibleToApplicant: boolean
  field: OzgelecekFieldEnum
  department: string
  language: LanguageEnums
  description: string
  place: PlaceOfWorkEnums
  workType: WorkTypeEnums
  cities: Array<string>
  districts: Array<string>
  positionLevel: PositionLevelEnums
  requiredAgeInterval: number
  requiredEducationLevel: HigherEducationDegreeEnum
  requiredSkills: Array<string>
  requiredLanguages: {
    name: string
    reading: LanguageEnums
    writing: LanguageEnums
    listening: LanguageEnums
  }
  militaryServiceRequired: boolean
  requiredDriverLicense: string
  companyInfoVisible: boolean
  images: Array<string>
  //replyNotification: string
  //customReplyNotification: {
  //  subject: string
  //  content: string
  //}
  createdAt: string
  updatedAt: string
}
export type IAnonymousRandomAdvertisementsResponse = IAnonymousRandomAdvertisementsItem[]

export interface IAnonymousRandomAdvertisementsVariables {
  size: number
}

export const anonymous_randomAdvertisements = (variables: IAnonymousRandomAdvertisementsVariables) => {

  const data = JSON.stringify({
    query: `query Anonymous_randomAdvertisements($size: Int!) {
      anonymous_randomAdvertisements(size: $size) {
          _id
        createdBy {
          _id 
         companyInformation {
            companyName
            profileImage
          }
        }

          title
          status
          productType
          extraProducts
          startDate
          quota
          field
          department
          language
          description
          place
          workType
          cities
          districts
          positionLevel
          requiredAgeInterval
          requiredEducationLevel
          requiredSkills
          requiredLanguages {
            name
            reading
            writing
            listening
          }
          militaryServiceRequired
          requiredDriverLicense
          images
          createdAt
          updatedAt
          companyInfoVisible
          quotaVisibleToApplicant
        
      }
    }
    `,
    variables: variables
  })

  return data

}