
import * as yup from 'yup'
import Slider from 'react-slick'
import { Formik, useFormikContext } from 'formik'
import Lottie from 'lottie-react'
import { LoadingButton } from '@mui/lab'
import { enqueueSnackbar } from 'notistack'
import { Box, Card, Container, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material'

import { enumFunctions } from 'utils'
import { Donusum1Animation, Donusum2Animation, OzgelecekSuperWoman, Pattern, Corede, InternIF, Locomar, BiTiklaSigorta } from 'assets'
import { FormikSelect, FormikTextField, FormikTextFieldPhone } from 'components'
import { IAnonymousCreateConversionRequestFormVariables, useAnonymous_createConversionRequestFormMutation, useGetCitiesQuery } from 'context'



export enum CompanyConversionNumberOfEmployeesEnum {
    upTo20 = "20'den az",
    between20_50 = "20 - 50 arası",
    between51_100 = "51 - 100 arası",
    between101_250 = "101 - 250 arası",
    greaterThan251 = "250 den fazla",
}

export const ConversionMainPage = () => {


    const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
    const [createConversion, { isLoading: createConversionLoading }] = useAnonymous_createConversionRequestFormMutation()


    const validationSchema = yup.object().shape({
        authorizedPersonEmail: yup.string().email("Geçerli bir email giriniz.").required("Gerekli!"),
        authorizedPersonName: yup.string().required("Gerekli!"),
        authorizedPersonPhoneNumber: yup.string().required("Gerekli!"),
        // .matches(/^[+][0-9]+$/, "Geçerli bir telefon numarası giriniz."),
        authorizedPersonSurname: yup.string().required("Gerekli!"),
        authorizedPersonTitle: yup.string().required("Gerekli!"),
        city: yup.string().required("Gerekli!"),
        companyName: yup.string().required("Gerekli!"),
        district: yup.string().required("Gerekli!"),
        numberOfEmployees: yup.string().required("Gerekli!"),
        sector: yup.string().required("Gerekli!"),
        website: yup.string().required("Gerekli!")
    })

    const initialvalues: IAnonymousCreateConversionRequestFormVariables = {
        authorizedPersonEmail: "",
        authorizedPersonName: "",
        authorizedPersonPhoneNumber: "",
        authorizedPersonSurname: "",
        authorizedPersonTitle: "",
        city: "",
        companyName: "",
        district: "",
        numberOfEmployees: "",
        sector: "",
        website: ""
    }

    const handleCreateConversion = async (values: IAnonymousCreateConversionRequestFormVariables, resetForm: any) => {
        try {
            const response: any = await createConversion(values)
            console.log({ response });

            if (response?.data) {
                // resetForm()
                enqueueSnackbar("Dönüşüm talebiniz başarıyla oluşturuldu.")
            } else {
                enqueueSnackbar("Talep oluşturulurken bir hata gerçekleşti.", { variant: 'warning' })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti.", { variant: 'error' })
        }
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '60px',
        pauseOnHover: false,
        slidesToShow: 3,
        speed: 500,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Stack spacing={3}>

            <Stack
                bgcolor={'#f8f8fe'}
                sx={{
                    mt: -11,
                    backgroundImage: `url(${Pattern})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: 'right',
                    pt: 11
                }}
            >
                <Container>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={7}>

                            {/* <Typography fontSize={40} fontWeight={700} color="primary" mt={5} >Burası Özgelecek,</Typography> */}
                            <Typography fontSize={40} fontWeight={700} mt={10} color="primary.darker" mb={5} >Özgelecek Dönüşüm! </Typography>
                            <Typography fontSize={18} fontWeight={600} color="primary.darker" mb={5} >
                                Özgelecek vizyonuyla firma çalışanlarınızın etkinlik ve verimliliğini %67’ye kadar artırmaya birkaç dakikanız kaldı. Aşağıdaki bildirimleri okuyarak ve yine sayfa sonundaki formu doldurarak 21. Yüzyıl becerileriyle etkinlik ve verimlilik artırma çalışmasının ilk adımını atın!
                            </Typography>
                            <Typography fontSize={20} fontWeight={700} color="primary.darker" mt={2} mb={2} >
                                Üstelik Ücretsiz!
                            </Typography>

                        </Grid>
                        {
                            !downMd && (
                                <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box component={"img"} src={OzgelecekSuperWoman} alt="OzgelecekSuperWoman" sx={{ height: 450 }} />
                                </Grid>
                            )
                        }
                    </Grid>
                </Container>
            </Stack>


            <Stack>
                <Container>
                    <Typography variant="h1" mt={2} textAlign={"center"} color="primary.main">
                        Özgelecek | Dönüşüm Nedir?
                    </Typography>
                    <Divider />

                    <Typography variant="body1" mt={2} textAlign={"center"}>
                        Günümüzde kısır döngüye dönmüş standart işe alım sistemlerinden ayrılarak;
                        iş yapma konusunda teknik anlamdaki bilgi ve deneyimleri bir kenara bırakıp
                        iş yapış biçimlerinde, etkin ve verimli çalışmanın ancak ve ancak iş
                        yapmaya duyulan “istek, ilgi, motivasyon” gibi konuların aslında en önemli
                        olduğunu, yani “işi sevsin ve çalışmak istesin, o işi yapan herkes başarılı olur”
                        önermesini kanıtlayan Özgelecek sistemi, firmalarda çalışan mevcut çalışanlar
                        için de departmanlar arası ve çalışma alanları arası bir dönüşüm ile firma
                        mevcut çalışanlarının verimliliğini artırma garantisi ile hizmet verdiği
                        bir danışmanlık modelidir.
                    </Typography>
                </Container>
            </Stack>


            <Stack>
                <Container>
                    <Grid container >

                        {/* <Grid item xs={12} >
                            <Typography variant="h3" mt={2} color="primary.dark">
                                Faydaları Nelerdir?
                            </Typography>
                            <Divider />
                        </Grid> */}

                        <Grid item xs={12} md={6} >

                            <Typography variant="h3" mt={2} color="primary.dark" textAlign="center">
                                Faydaları Nelerdir?
                            </Typography>

                            <Divider />
                            <Stack flex="1" p={2}>
                                <Typography variant="body1" textAlign={"justify"}>
                                    • Firmanızın Özgelecek sistemine yüzde kaç oranında ihtiyaç duyduğunu görmenizi sağlar,
                                </Typography>
                                <Typography variant="body1" mt={2} textAlign={"justify"}>
                                    • Firma çalışanlarınızın aslında hangi departmanlarda veya iş kollarında/alanlarında daha mutlu ve verimli olacağını istatistiksel olarak sunar,
                                </Typography>
                                <Typography variant="body1" mt={2} textAlign={"justify"}>
                                    • Özgelecek Dönüşüm modeli firmanızda işletildiğinde firma çalışanlarınızın verimliği ve mutluluğunun ne kadar artacağını istatistiksel olarak sunar,
                                </Typography>
                                <Typography variant="body1" mt={2} textAlign={"justify"}>
                                    • Özgelecek Dönüşüm modelinin çalışmaları tamamlandığında firma etkinlik ve verimliliğinizin ne kadar artacağını istatistiksel olarak sunar,
                                </Typography>
                                <Typography variant="body1" mt={2} textAlign={"justify"}>
                                    • Ve en önemlisi, mutlu, istekli ve motivasyonlu çalışanlar demek; daha çok kâr eden ve daha fazla gelir elde eden verimli bir firma demek olacağından doğrudan kazanç artışı sağlar!
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                            <Lottie
                                animationData={Donusum1Animation}
                                style={{ width: '70%', height: '100%' }}
                                loop={true}
                            />
                        </Grid>
                    </Grid >
                </Container>
            </Stack>


            <Stack>
                <Container>
                    <Grid container >



                        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ display: { xs: 'none', sm: 'initial' } }}>
                            <Lottie
                                animationData={Donusum2Animation}
                                style={{ width: '70%', height: '100%' }}
                                loop={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}   >
                            <Typography variant="h3" mt={2} color="primary.dark" textAlign="center">
                                Aşamaları Nelerdir?
                            </Typography>

                            <Divider />

                            <Stack flex="1" p={2}>
                                <Typography variant="body1" textAlign={"justify"} sx={{ fontWeight: 'bold' }}>
                                    • Talep Oluşturma
                                </Typography>
                                <Typography variant="body1" textAlign={"justify"}>
                                    Aşağıda bulunan talep formunu doldurun, Dönüşüm’e giden yolda talebinizi oluşturun! )
                                </Typography>
                                <Typography variant="body1" mt={2} textAlign={"justify"} sx={{ fontWeight: 'bold' }}>
                                    • Randevu Oluşturma
                                </Typography>
                                <Typography variant="body1" textAlign={"justify"}>
                                    Formu doldurduktan itibaren 24 saat içerisinde ekiplerimiz tarafından aranacaksınız. Bu çağrıda Özgelecek Dönüşüm hakkında detaylı bilgilendirme yaptıktan sonra ortak kararlaştırılan bir tarihte sizleri ziyaret edeceğiz.
                                </Typography>
                                <Typography variant="body1" mt={2} textAlign={"justify"} sx={{ fontWeight: 'bold' }}>
                                    • Çalışanlara Test Uygulama
                                </Typography>
                                <Typography variant="body1" textAlign={"justify"}>
                                    Bu ziyaretimizde ekip üyelerimiz tüm çalışanlarınıza tamamen ücretsiz bir test uygulayacak.
                                </Typography>
                                <Typography variant="body1" mt={2} textAlign={"justify"} sx={{ fontWeight: 'bold' }}>
                                    • Değerlendirme Toplantısı Gerçekleştirme
                                </Typography>
                                <Typography variant="body1" textAlign={"justify"}>
                                    Test sonuçlarını değerlendirme toplantısında “firmanızın Özgelecek Dönüşüm’e ne kadar ihtiyaç duyduğunu” istatistikler verilerle sizlere aktaracağız.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <Lottie
                                animationData={Donusum2Animation}
                                style={{ width: '70%', height: '100%' }}
                                loop={true}
                            />
                        </Grid>

                    </Grid >
                </Container>
            </Stack>

            <Stack
                sx={{
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: 'right',
                    py: 15,
                }}
            >
                <Container >
                    <Typography variant="h2" mt={4} mb={2} color="primary.main">
                        Firmalar Ne Düşünüyor?
                    </Typography>

                    <Slider {...settings}>
                        {testimonials.map((testimonial, index) => (
                            <TestimonialCard key={index} testimonial={testimonial} />
                        ))}
                    </Slider>
                </Container>
            </Stack>

            <Stack>
                <Container
                    sx={{
                        bgcolor: '#f8f8fe',
                        backgroundImage: `url(${Pattern})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: 'right',
                        py: 15,
                        p: { xs: 0, sm: 5 },
                        my: 15,
                        border: '1px solid #ccc'
                    }}
                >
                    <Stack alignItems="center" spacing={3} >
                        <Typography variant='h2' color="primary.dark">
                            Dönüşüm Formu
                        </Typography>
                    </Stack>

                    <Card sx={{ p: 3, m: 3 }}>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={initialvalues}
                            onSubmit={(values, { resetForm }) => {
                                handleCreateConversion(values, resetForm)
                            }}
                        >
                            {
                                ({ handleSubmit, }) => {
                                    return (
                                        <Stack spacing={3} >
                                            <CreateConversionFormContainer />
                                            <Stack direction="row" justifyContent="end">
                                                <LoadingButton
                                                    color='primary'
                                                    variant='contained'
                                                    loading={createConversionLoading}
                                                    disabled={createConversionLoading}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    Dönüşüm Talep Et
                                                </LoadingButton>
                                            </Stack>
                                        </Stack>
                                    )
                                }
                            }
                        </Formik>
                    </Card>
                </Container>
            </Stack>

        </Stack>
    )
}



const CreateConversionFormContainer = () => {

    const { values } = useFormikContext<any>()
    const { data: cities } = useGetCitiesQuery({ countryName: "Turkey" })


    return (
        <Grid container spacing={3}>

            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="authorizedPersonName"
                    label="İsim"
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="authorizedPersonSurname"
                    label="Soyisim"
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="authorizedPersonEmail"
                    label="Email"
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikTextFieldPhone
                    name="authorizedPersonPhoneNumber"
                    label="Telefon"
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="authorizedPersonTitle"
                    label="Ünvan"
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="website"
                    label="Website"
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="city"
                    label="Şehir"
                    list={cities?.data ?? []}
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="district"
                    label="İlçe"
                    list={cities?.data?.find((item) => item.name === values.city)?.districts ?? []}
                    disabled={!values.city}
                />
            </Grid>


            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="companyName"
                    label="İşyeri İsmi"
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="numberOfEmployees"
                    label="Çalışan Sayısı"
                    list={enumFunctions.objectlist(CompanyConversionNumberOfEmployeesEnum) ?? []}
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="sector"
                    label="Sektör"
                />
            </Grid>

        </Grid>
    )
}

const testimonials = [
    {
        label: 'Corede Technology ',
        image: Corede,
        description: 'Özgelecek vizyonuyla tanıştığımızda henüz bir çalışan ihtiyacımız bulunmuyordu. Ancak sistemi inceleyince vizyonun bizi çağdaş dönemde önemli bir noktaya taşıyabileceğini düşündük ve ardından Özgelecek Dönüşüm’le tanıştık. Verimlilik ve mutluluk paralelindeki bu çalışmanın böylesine bir etki yaratacağını düşünememiştim. Tüm ekip ve danışmanlara teşekkür ediyorum. ',
    },
    {
        label: 'InternIF',
        image: InternIF,
        description: 'Yürüttüğümüz iş operasyonu gereği çağdaş yaklaşımlara oldukça açığız. Özgelecek ile çalışan ararken tam da “acaba mevcut çalışanlarımızda da böyle bir ihtiyaç var mıdır?” diye düşünürken Dönüşümle karşılaştık. Gerek danışmanların çalışması gerekse de elde ettiğimiz sonuç inanılmaz denebilecek seviyedeydi. Sürecimiz hala devam ediyor ancak biz çok mutluyuz. ',
    },
    {
        label: 'Locomar',
        image: Locomar,
        description: 'Gerek local gerek ulusal gerekse de global ölçüde 360° pazarlama hizmeti veren bir şirketiz. %100 remote çalışma düzenimizde çalışanlarımızla yakın temas kurma konusunda etkili bir yöntemimiz yoktu, ta ki Özgelecek Dönüşüm’le tanışana kadar. Sistemleri gereği çalışanlarımızı “gerçekten” dinleyebiliyor, yenilenen organizasyon şemamızla etkili bir süreç ilerletebiliyoruz. Ekibe ve danışmanlara çok teşekkür ediyoruz. ',
    },
    {
        label: 'BiTıklaSigorta',
        image: BiTiklaSigorta,
        description: 'Şirketimiz gereği saha operasyonlarımız çok yoğun ilerliyor. Özgelecek vizyonu içerisinde işe aldığımız çalışanlardan çok iyi bir noktada verim alınca peşinden Dönüşüm’ü de işin içine katmak istedik. Dönüşümle birlikte biz de dönüşmüş olduk ve kurgulanan sistem itibariyle verimliliğimiz hatrı sayılır bir oranda arttı. Bu anlamda kendilerine çok teşekkür ediyoruz. ',
    },
];


function TestimonialCard({ testimonial }: any) {


    // Return the card component
    return (
        <Box
            sx={{
                width: { xs: '100%', md: 300 },
                height: '420px',
                // borderRadius: 1,
                backgroundColor: 'background.paper',
                boxShadow: 1,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'primary.lighter',
                p: 2,
                my: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            {/* <Avatar src={testimonial.image} alt={testimonial.label} /> */}
            <Box
                component="img"
                src={testimonial.image}
                alt={testimonial.label}
                sx={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    objectFit: 'contain',
                    overflow: 'hidden',
                }}
            />
            <Typography variant="subtitle2" component="div">
                {testimonial.label}
            </Typography>
            <Typography variant="body2" component="div">
                {testimonial.description}
            </Typography>
        </Box>
    );
}