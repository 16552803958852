import { Route } from 'react-router-dom'
import { ConversionMainPage, ReferencerMainPage } from 'pages'
import { routes } from './routes'
import { ConversionLayout, ReferencerLayout } from 'layouts'



export const conversionRouter = () => {

    return (
        <Route path='/' element={<ConversionLayout />}>
            <Route path="" element={<ConversionMainPage />} />
            {/* <Route path="*" element={<ReferencerMainPage />} /> */}
        </Route>
    )
}
