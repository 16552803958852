
import { Menu } from '@mui/icons-material';
import React, { ReactNode, useEffect } from 'react'
import { Outlet, useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Container, Divider, Grid, IconButton, List, MenuItem, Stack, Toolbar, Typography, useMediaQuery, SwipeableDrawer } from '@mui/material'

import { routes } from 'routes';
import { ICandidateAdvertisementsVariables, useGetCitiesQuery } from 'context'
import { AppStore, GooglePlay, IyzicoImage, Logo, LogoWhite, OzgelecekMain, Pattern } from 'assets'



interface IMainLayoutProps {
    children?: ReactNode | any
}



export const ConversionLayout = ({ children }: IMainLayoutProps) => {

    const navigate = useNavigate()
    const { pathname, hash } = useLocation();
    const [isSticky, setIsSticky] = React.useState(false);

    const handleScroll = () => {
        // Burada sticky durumunu kontrol ederek setIsSticky fonksiyonunu kullanarak güncelleyin.
        const scrollPosition = window.scrollY;
        if (scrollPosition > 0) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // useEffect(() => {
    //     if (hash) {
    //         let elem = document.getElementById(window.location.hash.slice(1))
    //         if (elem) {
    //             console.log({ hash, elem });
    //             elem.scrollIntoView({ behavior: "smooth" })
    //         }
    //     } else {
    //         window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    //     }

    // }, [hash, pathname]);



    return (
        <Stack>

            <Box sx={{ width: '100%', height: 5, bgcolor: 'primary.main' }} />

            <AppBar position="relative" sx={{ bgcolor: isSticky ? '#FFFFFFDD' : 'transparent', boxShadow: isSticky ? 5 : 0, transition: 'all 0.3s ease' }}>
                <Container >
                    <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', paddingLeft: '0px !important', paddingRight: '0px !important', py: 2 }}>

                        <Box
                            component={"img"}
                            src={Logo}
                            alt="Ozgelecek"
                            sx={{ width: 150, mr: 2, cursor: 'pointer' }}
                            onClick={() => navigate(routes.anonymous.main)}
                        />
                        <Button
                            color="primary"
                            variant='contained'

                            onClick={() => {
                                window.location.href = "https://ozgelecek.net"
                            }}
                        >
                            Siteye Git
                        </Button>

                    </Toolbar>
                </Container>
            </AppBar>

            <Stack>
                <Outlet />
            </Stack>

            {/* **********************  footer *********************** */}
            <Stack sx={{ bgcolor: '#202430', color: 'white', mt: 5, p: 2, width: '100%' }}>
                <Container maxWidth={"lg"} sx={{ mt: 3 }}>

                    <Grid container >
                        <Grid item xs={12} md={5} pr={3}>
                            <Box component={"img"} src={LogoWhite} alt="Ozgelecek" sx={{ width: 150 }} />
                            <Typography fontSize={15} color="white" mt={2} mb={2} >Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.</Typography>
                            {/* Google play ve app store indir butonları */}
                        </Grid>

                        <Grid item xs={12} md={4} display="flex" justifyContent={"center"} gap={10}>
                            {/* <Stack direction="column" >
                                <Typography fontSize={12} fontWeight={700} color="white" mb={2} >
                                    ÖZGELECEK
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.about}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Hakkımızda
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.firmaCozumleri}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Şirketler
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    // component={RouterLink}
                                    component="a"
                                    href='https://donusum.ozgelecek.net'
                                    sx={{ textDecoration: 'none' }}
                                // to={routes.anonymous.about}
                                >
                                    Dönüşüm
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.findAdvertisement}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    İlan Bul
                                </Typography>
                            </Stack> */}

                            {/* <Stack direction="column" >

                                <Typography fontSize={12} fontWeight={700} color="white" mb={2} >&nbsp;</Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.contact}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    İletişim
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.faq}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Sık Sorularn Sorular
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.kvkk}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    KVKK
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.onBilgilendirme}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Çerez Politikaları
                                </Typography>
                            </Stack> */}
                        </Grid>

                        <Grid item xs={12} md={3} display="flex" justifyContent={"flex-end"} gap={10}>
                            <Stack direction="column" gap={2}>
                                <Box component={"img"} src={GooglePlay} alt="Ozgelecek Google Play" sx={{ width: 150 }} />
                                <Box component={"img"} src={AppStore} alt="Ozgelecek App Store" sx={{ width: 150 }} />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 5, mb: 3 }} />

                    <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                        <Typography fontSize={12} color="white" textAlign="center" mb={3} >© 2024 Özgelecek. Tüm hakları saklıdır.</Typography>
                        <Box component={"img"} src={IyzicoImage} alt="Iyzico" sx={{ width: 250, bgcolor: 'white', px: 2, py: 1, }} mb={3} />
                    </Stack>

                </Container>
            </Stack>

        </Stack >
    )
}


